import React from "react"
import Layout from "../../components/Layout"
import uwuImg from "../../../static/blogs/2.jpg"

const Uwu2 = () => {
  return (
    <Layout>
      <main className={"artist-image w-100 flex-center-rw"}>
      </main>
      <section className={"artist-bio w-100"}>
        <div className={"artist-img"}>
          <img src={uwuImg} alt="aykunna artist image" />
          <a href="#" className={"follow-yt"} target="_blank">Watch on Youtube</a>
          <a href="#" className={"follow-tw"} target="_blank">Follow on Twitter</a>
        </div>
        <div className={"artist-info"}>
          <h2>Smiles And Adventure</h2>
          <p>One, two and three... She became familiar with colour as her parents
            painted the walls yellow and blue. At first, she made sounds of cows and
            cats. <br/><br/>
            She sat, walked, and ran one after another. Despite the slow start, she was
            almost there as she liked cakes and mango juice, which was on the tables. <br/><br/>
            The kites. The umbrella. The crayons. The paper boats. Little by little, it all
            saw her grow. She was taken to the mountains each Sunday. She was not
            tired of the see-saw in the park, so on Tuesdays, they took her there.
            Thursdays were beach days. <br/><br/>
            Little as a cupcake, just as happy as the ducks. She was everything a mind
            dreams of.</p>
        </div>
      </section>
    </Layout>
  )
}

export default Uwu2